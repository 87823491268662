// Update the count down every 1 second
var x = setInterval(function() {

  // Opkomende zomer jaartal opzoeken
  var today = new Date();
  if (today.getMonth() > 07) {
  var yyyy = today.getFullYear() +1;
  } else {
    var yyyy = today.getFullYear();
  }

  // Set the date we're counting down to
  var countDownDate = new Date(yyyy + "-06-21").getTime();

  // Get todays date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now + (today.getTimezoneOffset() * 60 * 1000);

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  var daysText = "dagen";
  var hoursText = "uur";
  var minutesText = "minuten";
  var secondsText = "seconden";

  // If the count down is finished, write some text
  // getMonth 0 = January
  // getMonth 11 = December

  // First months
  var currentInfoText = "Nee, het is nog lang geen zomer.";
  document.getElementById("js-current-info-text").innerHTML = currentInfoText;

  // Maart
  if (today.getMonth() === 02) {
    currentInfoText = "Ik begrijp je enthousiasme, maar je moet nog heel even wachten.";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // April
  if (today.getMonth() === 03) {
    currentInfoText = "Rustig aan, het is net pas lente.<br /> Je moet nog heel even wachten op de zomer";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

    // Mei
    if (today.getMonth() === 04) {
      currentInfoText = "Het duurt niet lang meer voordat het zomer is!";
      document.getElementById("js-current-info-text").innerHTML = currentInfoText;
    }

  // Juni
  if (today.getDate() < 21 && today.getMonth() === 05) {
    currentInfoText = "Het is bijna zo ver!<br />  Trek die korte broek maar alvast aan!";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Eerste zomerdag - juni
  if (today.getMonth() === 05 && today.getDate() > 20) {
    document.getElementById('js-countdown-zomer').style.display = 'none';
    currentInfoText = "JA, het is zomer! <br /> Fijne zomer!";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Overige zomerdagen - juli - augustus
  if (today.getMonth() === 06 || today.getMonth() === 07) {
    document.getElementById('js-countdown-zomer').style.display = 'none';
    currentInfoText = "JA, het is zomer! <br /> Fijne zomer!";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Tot einde zomer - september
  if (today.getMonth() === 08 && today.getDate() < 21) {
    document.getElementById('js-countdown-zomer').style.display = 'none';
    currentInfoText = "JA, het is zomer! <br /> Fijne zomer!";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // September - net zomer geweest
  if (today.getDate() > 20 && today.getMonth() === 08) {
    document.getElementById('js-countdown-zomer').style.display = 'block';
    currentInfoText = "Nee, nog lang niet. <br / > Het is net zomer geweest..";
    document.getElementById("js-current-info-text").innerHTML = currentInfoText;
  }

  // Display the result in the elements
  document.getElementById("js-number-days").innerHTML = days;
  document.getElementById("js-number-hours").innerHTML = hours;
  document.getElementById("js-number-minutes").innerHTML = minutes;
  document.getElementById("js-number-seconds").innerHTML = seconds;

  document.getElementById("js-text-days").innerHTML = daysText;
  document.getElementById("js-text-hours").innerHTML = hoursText;
  document.getElementById("js-text-minutes").innerHTML = minutesText;
  document.getElementById("js-text-seconds").innerHTML = secondsText;

}, 1000);
